import React from 'react';
import AppLayout from '../independents/AppLayout';

const AboutPage: React.FC = () => {
  return (
    <AppLayout>
      <h1>About</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis repellat nemo eum tempora accusamus voluptas corporis voluptatum eius impedit nobis delectus, in voluptate dolore vel! Quis sunt dolore minus ullam?
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate repellat quos doloremque rerum illum eius? Aut culpa excepturi soluta quaerat molestias explicabo cumque mollitia nesciunt, labore totam, eveniet blanditiis iure.
      </p>
    </AppLayout>
  );
}

export default AboutPage;
