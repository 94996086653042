import React from 'react';
import AppLayout from '../independents/AppLayout';

const NotFoundPage: React.FC = () => {
  return (
    <AppLayout>
      <h1>Not Found</h1>
    </AppLayout>
  );
}

export default NotFoundPage;
